<template>
    <div id="silo-card-overview-component">
        <v-card elevation="0">
            <asset-card-alerts-component :asset="silo" />

            <v-card-title class="font-weight-bold pb-2">
                <p class="mb-1">{{ silo.name }}</p>

                <v-spacer />

                <menu-actions-component
                    :actions="[
                        {
                            title: $t('list_tank_equipmement_component.see_cuve_detail'),
                            icon: 'mdi-eye-outline',
                            emit: 'goToAssetDetails',
                            has_access: true,
                        },
                    ]"
                    v-on:goToAssetDetails="$router.push('/equipment/tank/' + silo.id_asset)"
                />
            </v-card-title>

            <v-card-subtitle>
                <p class="font-weight-light font-12 ml-n1 text-overflow-ellipsis">
                    <v-icon dense>mdi-map-marker-outline</v-icon>
                    <span>{{ getFormattedAddress(silo.domicile) }}</span>
                </p>
            </v-card-subtitle>

            <v-card-text>
                <v-row align="center" class="ma-0 mb-2" justify="center" style="height: 180px">
                    <v-col class="pa-0">
                        <div class="d-flex justify-center">
                            <display-asset-level-component :asset="silo" show_indicator />
                        </div>
                    </v-col>
                </v-row>

                <v-row class="text-center font-12 mb-4">
                    <v-col>
                        <span class="font-color-medium">{{ $t('list_tank_equipmement_component.volume') }}</span>
                        <p class="mb-0 font-weight-bold">00</p>
                    </v-col>
                    <v-divider color="white" vertical />

                    <v-col>
                        <span class="font-color-medium">{{ $t('list_tank_equipmement_component.volume') }}</span>
                        <p class="mb-0 font-weight-bold">00</p>
                    </v-col>
                    <v-divider color="white" vertical />
                    <v-col>
                        <span class="font-color-medium">{{ $t('list_tank_equipmement_component.volume') }}</span>
                        <p class="mb-0 font-weight-bold">00</p>
                    </v-col>
                    <v-divider color="white" vertical />
                    <v-col>
                        <span class="font-color-medium">{{ $t('list_tank_equipmement_component.volume') }}</span>
                        <p class="mb-0 font-weight-bold">00</p>
                    </v-col>
                    <v-divider color="white" vertical />
                </v-row>

                <div v-for="device in silo.devices" :key="device.id_device" class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                        <img :src="device.path_image_device_referentiel" height="50" />
                        {{ device.device_number }}
                    </div>

                    <div>
                        <chips-last-statement
                            v-if="device.date_last_seen && silo.is_all_subscription_device_active && silo.is_subscription_device_active"
                            :date="device.date_last_seen"
                            :previous_text="$t('list_tank_equipmement_component.update')"
                            class="pointer"
                        />
                        <v-chip v-else outlined small>{{ $t('list_tank_equipmement_component.no_update') }}</v-chip>
                    </div>
                </div>

                <v-row v-if="display_detail_button" class="pt-2">
                    <v-col>
                        <v-btn block color="grey" elevation="0" outlined rounded>
                            <span class="font-weight-bold primary--text">Voir le détail</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import AssetCardAlertsComponent from '@/components/Alert/AssetCardAlertsComponent.vue'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'
import DisplayAssetLevelComponent from '@/components/Equipment/LiquidLevelComponent/DisplayAssetLevelComponent.vue'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'

export default {
    name: 'SiloCardOverviewComponent',
    components: { ChipsLastStatement, DisplayAssetLevelComponent, MenuActionsComponent, AssetCardAlertsComponent },
    props: {
        silo: {
            type: Object,
            required: true,
        },
        display_detail_button: {
            type: Boolean,
            default: false,
        },
    },
}
</script>